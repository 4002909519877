<template>
  <div>
    <div class="main-title">文件列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">文件格式支持：MP3</li>
            <li data-v-69874ad0="">文件大小不能超过20M</li>
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select
            v-model="type"
            clearable
            placeholder="选择分类"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input
            size="small"
            placeholder="文件名称"
            v-model="name"
            clearable
          >
          </el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="margin-l-sm fl-l">
          <router-link to="/audio/add" class="router-link">
            <el-button type="primary" size="small">上传音频</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <div class="file-list" v-loading="loading">
          <div class="file-item" v-for="(file, index) in files" :key="index">
            <div class="file-item-top">
              <span>
                <el-button type="primary" size="mini" plain @click="playAudio(file)">
                  播放
                </el-button>
              </span>
              <div class="file-info">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="file.name"
                  placement="top"
                >
                  <p class="file-info-title el-icon-microphone">
                    {{ file.name }}
                  </p>
                </el-tooltip>
                <div class="file-info-size">
                  <span>{{
                    parseFloat(file.size / 1024 / 1024).toFixed(2) + " M"
                  }}</span>
                  <div class="clear"></div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="file-option margin-l-sm">
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="top"
              >
                <i class="el-icon-delete" @click="delBtn(file)">
                  <el-popover placement="top" width="160" v-model="file.isDel">
                    <p>是否删除,删除后不可恢复?</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="danger"
                        v-on:click.stop="hiddleBtn(file)"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        v-on:click.stop="delFile(file)"
                        :loading="file.btnLoad"
                        >确定</el-button
                      >
                    </div>
                  </el-popover>
                </i>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="重命名"
                placement="top"
              >
                <i
                  class="el-icon-edit-outline"
                  v-on:click.stop="showSetName(file)"
                >
                  <el-popover
                    placement="top"
                    width="260"
                    v-model="file.setName"
                  >
                    <p class="set-name">
                      <el-input
                        size="mini"
                        v-model="file.name"
                        placeholder="请输入内容"
                      ></el-input>
                    </p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="danger"
                        v-on:click.stop="hideSetName(file)"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        v-on:click.stop="setName(file)"
                        :loading="file.btnLoad"
                        >确定</el-button
                      >
                    </div>
                  </el-popover>
                </i>
              </el-tooltip>
            </div>
          </div>
          <el-dialog
            append-to-body
            title="音频播放"
            :visible.sync="audioVisible"
            width="35%"
            :before-close="handleClose"
          >
            <audio
              :src="path + audioUrl"
              :autoplay="false"
              controls
              autoloop
            ></audio>
          </el-dialog>
        </div>
      </div>

      <div class="table-batch">
        <span class="fl-l margin-l-sm">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tools from "../../../utils/tools";
import { config } from "../../../js/config";

export default {
  name: "list",
  data() {
    return {
        audioUrl :'',
      path: config.imageUrl,
      loading: false,
      visible: true,
      files: [],
      page: 1,
      name: "",
      pageSize: 10,
      total: 0,
      imageUrl: config.imageUrl,
      options: [
        {
          value: "1",
          label: "用户上传",
        },
        {
          value: "2",
          label: "后台上传",
        },
      ],
      type: "",
      tableData: [],
      currentRow: null,
      audioVisible: false,
    };
  },
  methods: {
    ...mapActions("audio", ["getAudioList", "editAudio", "delAudio"]),
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    s_to_hs(s) {
      return tools.sToTime(s);
    },
    getList() {
      this.loading = true;
      let data = {
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.name != "") {
        data.name = this.name;
      }
      if (this.type != "") {
        data.type = Number(this.type);
      }

      this.getAudioList(data).then((res) => {
        this.loading = false;
        let data = [];
        res.data.list.forEach((item) => {
          data.push({
            id: item.id,
            name: item.name,
            url: item.url,
            size: item.size,
            setName: false,
            isDel: false,
            btnLoad: false,
          });
        });
        this.total = res.data.total;
        this.files = data;
        this.loading = false;
      });
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    showSetName(file) {
      file.setName = true;
    },
    hideSetName(file) {
      file.setName = false;
    },
    setName(file) {
      let data = {
        id: file.id,
        name: file.name,
      };
      file.btnLoad = true;
      this.editAudio(data).then((res) => {
        if (res.ret == 0) {
          this.$message({
            message: "修改成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: res.res_info,
            type: "error",
          });
        }
        this.hideSetName(file);
        file.btnLoad = false;
      });
    },
    delBtn(file) {
      file.isDel = true;
    },
    hiddleBtn(file) {
      file.isDel = false;
    },
    // 删除
    async delFile(file) {
      file.btnLoad = true;
      const { res_info, data } = await this.delAudio(file.id);
      if (res_info !== "ok") return;
      file.btnLoad = false;
      this.$message.success("删除成功!");
      this.getList();
    },
    playAudio(file) {
        this.audioUrl = file.url
      this.audioVisible = true;
    },
    handleClose() {
      this.audioVisible = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.file-list {
  display: flex;
  flex-wrap: wrap;
}
.el-card__body {
  padding: 10px;
}
.file-item {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 309px;
  border: 1px solid #ebebeb;
}
.video-icon {
  float: left;
  width: 100px;
  height: 55px;
}
.file-info {
  float: left;
  margin-left: 10px;
}
.file-item-top {
  padding: 12px 10px 12px 12px;
}
.file-info-title {
  color: #333;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}
.file-info-size {
  color: #999;
  font-size: 12px;
}
.file-option {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  background: #fafafa;
  border-top: 1px solid #ebebeb;
}
.file-option .item {
  color: #aaaaaa;
  cursor: pointer;
  margin-right: 10px;
}
.clear {
  clear: both;
}
.set-name {
  padding-bottom: 10px;
}
</style>
